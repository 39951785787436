<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on }">
			<v-btn :color="hasText ? 'primary' : ''" :icon="hasOnlyIcon" :flat="!hasOnlyIcon" v-on="on" @click="sort()">
				<v-badge color="" overlap :left="hasText" :top="isAscSorting" :bottom="isDescSorting">
					<template v-slot:badge>
						<v-icon color="secondary">{{ arrowIcon }}</v-icon>
					</template>
					<v-layout justify-center align-center>
						<v-icon v-if="hasIcon" :left="hasText" :color="hasText ? '' : 'primary'">{{ icon }}</v-icon>
						<v-flex v-if="hasText">{{ textValue }}</v-flex>
					</v-layout>
				</v-badge>
			</v-btn>
		</template>
		<span v-text="tooltip" />
	</v-tooltip>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: 'SortButton',
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		},
		icon: {
			type: String,
			required: false,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		text: {
			type: String,
			required: false,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		textAsc: {
			type: String,
			required: false,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		textDesc: {
			type: String,
			required: false,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		tooltipAsc: {
			type: String,
			required: true,
			validator: propValue => propValue.trim().length
		},
		tooltipDesc: {
			type: String,
			required: true,
			validator: propValue => propValue.trim().length
		},
		tooltipCancel: {
			type: String,
			required: true,
			validator: propValue => propValue.trim().length
		}
	},
	computed: {
		sortOrder: function () {
			return this.value?.sort?.order || '';
		},
		hasIcon: function () {
			return this.icon !== null
		},
		hasText: function () {
			return this.text !== null
		},
		textValue: function () {
			let result
			if (this.isAscSorting && this.textDesc !== null) {
				result = this.textDesc
			} else if (this.isDescSorting && this.text !== null) {
				result = this.text
			} else if (!this.isAscSorting && !this.isDescSorting && this.textAsc !== null) {
				result = this.textAsc
			} else {
				result = this.text
			}
			return result
		},
		hasOnlyIcon: function () {
			return this.hasIcon && !this.hasText
		},
		isAscSorting: function () {
			return this.sortOrder === 'asc'
		},
		isDescSorting: function () {
			return this.sortOrder === 'desc'
		},
		arrowIcon: function () {
			let result = ''
			if (this.isAscSorting) {
				result = 'arrow_upward'
			} else if (this.isDescSorting) {
				result = 'arrow_downward'
			}
			return result
		},
		tooltip: function () {
			let result = this.tooltipAsc
			if (this.sortOrder === 'desc') {
				result = this.tooltipCancel
			} else if (this.sortOrder === 'asc') {
				result = this.tooltipDesc
			}
			return result
		}
	},
	watch: {
		value (newValue) {
			if (!newValue || newValue.sort?.order === undefined) {
				return;
			}
			this.setSort({
				folderId: newValue.id,
				sort: {
					field: newValue.sort.field,
					order: newValue.sort.order
				}
			})
		}
	},
	methods: {
		...mapActions('documents', ['setSort']),
		sort: function () {
			let result = { folderId: this.value.id, sort: { field: 'name', order: '' } }
			switch (this.sortOrder) {
				case '':
					result.sort.order = 'asc'
					break
				case 'asc':
					result.sort.order = 'desc'
					break
				case 'desc':
					result.sort.order = ''
					break
				default:
					result.sort.order = ''
					break
			}
			this.setSort(result)
		}
	}
}
</script>
